import React from 'react';
import cardStyles from './PostcardBack.module.css'
import divider from '../images/postcard-divider.png'

function PostcardBack(props) {

    const {
        onChangeMessage: setMessage,
        message,
        prefab
    } = props;

    function handleMessageChange(e) {
        setMessage(e.target.value)
    }
    
    return (
        <div className={cardStyles.card}>
            <textarea key={prefab} placeholder="Start typing here to customize your message, or choose a template below." className={cardStyles.message} onChange={handleMessageChange} value={message} />
            <img src={divider} className={cardStyles.divider} alt="divider" />
        </div>
    )
}

export default PostcardBack