import React from 'react';
import cardStyles from './PostcardFront.module.css'
import ColorPicker from './ColorPicker'

function PostcardFront(props) {

    const {
        color,
        handleColorChange
    } = props

    const image = require(`../images/pn-pc-${color}.svg`)

    return (
        <div>
            <img src={image} className={cardStyles.card} alt="Postcard Background" />
            <ColorPicker onChange={handleColorChange} currentColor={color} />
        </div>

    )

}

export default PostcardFront