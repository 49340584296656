import React, { useState, useRef, useEffect } from "react";
import PostcardFront from "./components/PostcardFront";
import PostcardBack from "./components/PostcardBack";
import PrefabOptions from "./components/PrefabOptions";
import Divider from "./components/Divider";
import Layout from "./components/Layout";
import UserInfo from "./components/UserInfo";
import generatorStyles from "./PostcardGeneratorPage.module.css";
import divider from "./images/postcard-divider.png";
import * as jsPDF from "jspdf";
import canvg from "canvg"; // has to be in here for ctx.drawSvg( ... to work
import { BrowserRouter as Router, Route } from "react-router-dom";

function PostcardGeneratorPage({ match }) {
  const slug = match.params.slug || null;
  const redirectUrl =
    slug === "timeline"
      ? "https://principalproject.org/timeline?ref=pp_gen_timeline"
      : "https://principalproject.org/problem-solver?ref=pp_gen_problemsolver";
  const logoUrl =
    slug === "timeline"
      ? "https://principalproject.org/timeline?ref=pp_genlg_timeline"
      : "https://principalproject.org/problem-solver?ref=pp_genlg_problemsolver";

  const canvasRef = useRef(null);
  const dividerRef = useRef(null);
  const frontRef = useRef(null);
  const backRef = useRef(null);

  const [infoSent, setInfoSent] = useState(false);
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [message, setMessage] = useState("");
  const [prefab, setPrefab] = useState("default");
  const [userInfo, setUserInfo] = useState({
    nameFirst: "",
    nameLast: "",
    email: "",
  });
  const [color, setColor] = useState("lime");
  const [frontRendered, setFrontRendered] = useState(null);
  const [backRendered, setBackRendered] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [media, setMedia] = useState(null);
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    const fetchCopy = async () => {
      const res = await fetch(
        `https://principalproject.org/wp-json/wp/v2/pages/7`
      );
      const data = await res.json();
      setHeader(data.title.rendered);
      setBody(data.content.rendered);
    };
    fetchCopy();
  }, []);

  useEffect(() => {
    function exportPNG() {
      const ctx = canvasRef.current.getContext("2d");

      ctx.clearRect(0, 0, 1720, 2480);
      canvasRef.current.width = 1720;
      canvasRef.current.height = 2480;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, 1720, 2480);

      ctx.drawImage(frontRef.current, 0, 0, 1720, 1230);
      ctx.drawImage(backRef.current, 0, 1260, 1720, 1230);

      var url = canvasRef.current.toDataURL("image/png");
      var link = document.createElement("a");
      link.download = "postcard.png";
      link.href = url; //canvasRef.currentt.toDataUrl()
      link.click();
      setDownloadStarted(false);
    }

    function exportPDF() {
      const doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: "a6",
        putOnlyUsedFonts: true,
      });
      doc.addImage(frontRendered, 0, 0, 148, 105);
      doc.addPage({
        format: "a6",
        orientation: "l",
      });
      doc.addImage(backRendered, 0, 0, 148, 105);
      doc.save("postcard.pdf");
      setDownloadStarted(false);
    }

    if (frontRendered && backRendered && media) {
      if (media === "png") exportPNG();
      if (media === "pdf") exportPDF();
    }
  }, [frontRendered, backRendered, media]);

  function handlePrefabSelect(newMessage) {
    if (error === "message") {
      setError(null);
      setErrorMessage("");
    }
    setMessage(newMessage);
    setPrefab(newMessage);
  }

  function handleMessageChange(newMessage) {
    if (error === "message" && newMessage !== "") {
      setError(null);
      setErrorMessage("");
    }

    setMessage(newMessage);
  }

  function handleUserInfoChange(key, value) {
    if (error === key && value !== "") {
      setError(null);
      setErrorMessage("");
    }
    const update = {};
    update[key] = value;
    setUserInfo({
      ...userInfo,
      ...update,
    });
  }

  function validateForm() {
    if (message.trim() === "") {
      setError("message");
      setErrorMessage("Please enter a message to continue.");
      return false;
    }
    if (userInfo.nameFirst.trim() === "") {
      setError("nameFirst");
      setErrorMessage("Please enter your first name to continue.");
      return false;
    }
    if (userInfo.nameLast.trim() === "") {
      setError("nameLast");
      setErrorMessage("Please enter your last name to continue.");
      return false;
    }
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (userInfo.email.trim() === "" || !userInfo.email.match(emailRegex)) {
      // @todo implement another mechanism
      setError("email");
      setErrorMessage("Please enter your email address to continue.");
      return false;
    }
    return true;
  }

  async function downloadPostcard(selectedMedia) {
    if (downloadStarted) return;
    setDownloadStarted(true);
    setError(null);

    if (!validateForm()) {
      // check info
      setDownloadStarted(false);
      return;
    }

    setMedia(selectedMedia);
    // send api request to save their information
    let result = false;
    if (!infoSent) result = await sendUserInfo();
    setInfoSent(true);

    if (result)
      // generate svg for postcard pdf
      buildPostcard();
  }

  async function sendUserInfo() {
    try {
      const req = {
        ...userInfo,
        message,
        color,
      };
      const lambdaHandler = `https://pwewru8r54.execute-api.us-east-1.amazonaws.com/stage`;
      const res = await fetch(lambdaHandler, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          body: JSON.stringify(req),
        }),
      });
      console.log(res);
      return true;
    } catch (err) {
      setError("api");
      setErrorMessage(`An error occurred trying to contact pardot: ${err}`);
      return false;
    }
  }

  function buildPostcard() {
    const ctx = canvasRef.current.getContext("2d");
    const image = require(`./images/pn-pc-${color}.svg`);

    ctx.clearRect(0, 0, 1720, 1230);
    canvasRef.current.width = 1720;
    canvasRef.current.height = 1230;

    ctx.drawSvg(image, 0, 0, 1720, 1230);
    setFrontRendered(canvasRef.current.toDataURL("image/png"));

    ctx.clearRect(0, 0, 1720, 1230);

    ctx.font = "42pt Avenir, sans-serif";
    wrapText(ctx, message, 100, 100, 863, 56);

    ctx.drawImage(dividerRef.current, 980, 10, 5, 1200);
    setBackRendered(canvasRef.current.toDataURL("image/png"));
  }

  function wrapText(ctx, text, x, y, maxWidth, lineHeight) {
    text = text.replace(/\r\n/g, ` [NEWLINE] `);
    const words = text.split(" ");
    let line = "";
    for (let n = 0; n < words.length; n++) {
      const word = words[n];
      if (word === "[NEWLINE]") {
        if (line !== "") ctx.fillText(line, x, y);
        y += lineHeight;
        line = "";
      } else if (word !== "") {
        const testLine = line + word + " ";
        const metrics = ctx.measureText(testLine);
        if (metrics.width > maxWidth && n > 0) {
          ctx.fillText(line, x, y);
          line = word + " ";
          y += lineHeight;
        } else {
          line = testLine;
        }
      }
    }

    ctx.fillText(line, x, y);
  }

  return (
    <Layout homeLink={logoUrl}>
      <h1 className={generatorStyles.header}>{header}</h1>
      <div dangerouslySetInnerHTML={{ __html: body }}></div>

      <Divider />

      <h4 className={generatorStyles.subheader}>Front of the postcard:</h4>
      <PostcardFront handleColorChange={setColor} color={color} />

      <Divider />

      <h4 className={generatorStyles.subheader}>Back of the postcard:</h4>
      <PostcardBack
        message={message}
        onChangeMessage={handleMessageChange}
        prefab={prefab}
      />

      <Divider />

      <h4 className={generatorStyles.subheader}>Inspire me!</h4>
      <PrefabOptions onSelectPrefab={handlePrefabSelect} />

      <Divider />

      <h4 className={generatorStyles.subheader}>Your information:</h4>
      <UserInfo onChange={handleUserInfoChange} userInfo={userInfo} />

      {error && errorMessage && (
        <div className={generatorStyles.error}>
          <i
            className="fa fa-exclamation-circle"
            style={{ color: "#444", marginRight: "0.5rem" }}
          ></i>{" "}
          {errorMessage}
        </div>
      )}
      <div className={generatorStyles.buttonContainer}>
        <button
          className={generatorStyles.download}
          onClick={(e) => downloadPostcard("pdf")}
        >
          <i className="fa fa-download"></i>
          Download PDF
        </button>
        <button
          className={generatorStyles.download}
          onClick={(e) => downloadPostcard("png")}
        >
          <i className="fa fa-download"></i>
          Download PNG
        </button>
      </div>

      <div className={generatorStyles.buttonContainer}>
        <p style={{ textAlign: "center", width: "100%" }}>
          Share the attendance postcard practice with your fellow school
          leaders!
        </p>
      </div>
      <div className={generatorStyles.buttonContainer}>
        <a
          className={generatorStyles.share}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://twitter.com/intent/tweet?url=${redirectUrl}&text=${encodeURI(
            `We’re exploring the postcard practice for improving attendance. If you’re curious about a simple practice for getting kids to school, you can join us in learning more here! `
          )}`}
        >
          <i className="fa fa-twitter"></i>
          Share on Twitter
        </a>
      </div>
      <canvas
        style={{ display: "none", width: 1720, height: 1230 }}
        ref={canvasRef}
      />
      {
        <img
          style={{ display: "none" }}
          src={divider}
          ref={dividerRef}
          alt="divider"
        />
      }
      {frontRendered && (
        <img
          style={{ display: "none" }}
          src={frontRendered}
          ref={frontRef}
          alt=""
        />
      )}
      {backRendered && (
        <img
          style={{ display: "none" }}
          src={backRendered}
          ref={backRef}
          alt=""
        />
      )}
    </Layout>
  );
}

function App() {
  return (
    <Router>
      <Route strict path="/:slug" component={PostcardGeneratorPage} />
      <Route exact path="/" component={PostcardGeneratorPage} />
    </Router>
  );
}
export default App;
