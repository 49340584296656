import React from 'react' 
import infoStyles from './UserInfo.module.css'

function UserInfo(props) {

    const {
        userInfo: {
            nameFirst,
            nameLast,
            email
        }, 
        onChange: handleUserInfoChange,
    } = props;

    return (
        <form className={infoStyles.form}>
            <input className={infoStyles.input} type="text" placeholder="First Name" value={nameFirst} onChange={e => handleUserInfoChange('nameFirst', e.target.value)} />
            <input className={infoStyles.input} type="text" placeholder="Last Name" value={nameLast} onChange={e => handleUserInfoChange('nameLast', e.target.value)} />
            <input className={infoStyles.input} type="text" placeholder="Email" value={email} onChange={e => handleUserInfoChange('email', e.target.value)} />
        </form>
    )
}

export default UserInfo