import React, { useState } from 'react';

import prefabStyles from './PrefabOptions.module.css'

function PrefabOptions(props) {

    const {
        onSelectPrefab: setMessage
    } = props;

    const options = [
        [
            `We’re writing because __________________ has been absent ____ days so far this school year. We’d love to see your student more!`,
            `Missing just two days of school per month can get in the way of students’ success, but families can play an important role in making sure kids get to school as often as possible. Let us know if you have questions – we’re here to help and we’d love to connect. `,
        ],
        `Missing about two days per month can add up – to almost 18 days for the school year – and can get in the way of your students’ success. We'd love to see your student more! If there's something getting in the way, please let us know. We're here to help.`,
        `Every day at school counts, and we need your help to support your student! Families can make a big difference in helping to improve students’ attendance.  Just two absences per month can add up to weeks of lost learning. Let us know if you have questions – we’re here to help and we’d love to connect. `,
    ]

    const [currentOption, setCurrentOption] = useState(0)

    function clickArrow(direction) {
        const diff = direction === 'left' ? -1 : 1;
        setCurrentOption((currentOption + diff + options.length ) % options.length)
    }


    function copyCurrent(e) {
        const newMessage = typeof options[currentOption] === 'string'
            ? options[currentOption]
            : options[currentOption].join("\r\n\r\n")

        setMessage(newMessage)
    }

    return (
        <div>
            <p>Here are a few examples you might use or adapt to be true to you and your school community. <b>Like what you see?? Just click to copy and customize above!</b></p>
            <div className={prefabStyles.control}>
                <div className={prefabStyles.arrow} onClick={e => clickArrow('left')}>
                    <i className="fa fa-chevron-circle-left" />
                </div>
                <div className={prefabStyles.current} onClick={copyCurrent}>
                    {typeof options[currentOption] === 'string' && <p>{options[currentOption]}</p>}
                    {typeof options[currentOption] === 'object' && options[currentOption].map(paragraph => <p key={paragraph}>{paragraph}</p>)}
                </div>
                <div className={prefabStyles.arrow} onClick={e => clickArrow('right')}>
                    <i className="fa fa-chevron-circle-right" />
                </div>
            </div>
        </div>
    )

}


export default PrefabOptions;