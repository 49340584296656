import React from 'react';
import colorPickerStyles from './ColorPicker.module.css'

function ColorPicker(props) {

    const { 
        onChange: handleColorChange,
        currentColor
    } = props;

    const colors = {
        lime: '#cede00',
        red: '#f93728',
        blue:'#005788',
        teal: '#00b0be',
        gold: '#ffcc00',
    }

    return(
        <div style={{textAlign: 'center'}}>
            <div className={colorPickerStyles.listContainer}>
                <ul className={colorPickerStyles.list}>
                    {Object.keys(colors).map(color => <ColorOption key={`color-option-${color}`} onClick={e => handleColorChange(color)} hex={colors[color]} isSelected={color===currentColor} />)}
                </ul>
            </div>
        </div>
    )
}


function ColorOption(props) {

    const { 
        isSelected, 
        hex,
        onClick: handleColorChange
    } = props;

    return (
        <li className={colorPickerStyles.option} onClick={handleColorChange}>
            <button>
                {isSelected && 
                    <div className={colorPickerStyles.selected}></div>
                }
            </button>
            <div className={colorPickerStyles.box} style={{backgroundColor: hex}}></div>
        </li>
    )
}

export default ColorPicker;