import React from 'react';
import logo from '../images/logo.svg';
import gatesFoundationLogo from '../images/gates-foundation.png';
import teacher2teacherLogo from '../images/t2t_logo.9159303e.png'
import layoutStyles from './Layout.module.css';
import Link from './Link'

function Layout(props) {

    const { homeLink } = props;

    return (
        <div className={layoutStyles.container}>
            <main className={layoutStyles.main}>
                <header className={layoutStyles.header}>
                    <div className={layoutStyles.headerContainer}>
                        <Link to={homeLink} target="_blank">
                            <img src={logo} className={layoutStyles.logo} alt="logo" />
                        </Link>
                    </div>
                </header>

                <div className={layoutStyles.content}>
                    {props.children}
                </div>
            </main>
      
            <footer className={layoutStyles.footer}>
                <div className={layoutStyles.footerContainer}>
                    <p className={layoutStyles.footerIntro}>Supported by</p>
                    <div className={layoutStyles.footerSupporters}>
                        <Link to="http://k12education.gatesfoundation.org/" target="_blank">
                            <img src={gatesFoundationLogo} alt="Gates Foundation Logo" className={layoutStyles.supporter} />
                        </Link>
                        <Link to="https://www.teacher2teacher.education/" target="_blank">
                            <img src={teacher2teacherLogo} alt="Teacher 2 Teacher Logo" className={layoutStyles.supporter} />
                        </Link>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Layout;