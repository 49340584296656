import React from 'react';

function Link(props) {

    const { to, target } = props;

    if(target === '_blank') 
        return (<a href={to} target="_blank" rel="noopener noreferrer">{props.children}</a>);
    else 
        return (<a href={to}>{props.children}</a>);
}

export default Link;