import React from 'react';
import divider from '../images/divider.png';
function Divider (props) {
    return (<img src={divider} alt="divider" style={{
        margin: '0.5rem auto', 
        width: 'auto', 
        maxWidth: '100%'
    }} />)
}

export default Divider